<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-multiline">
        <div class="column is-10">
          <div class="block">
            <p class="paragraph text_green_dark has-text-weight-bold"> Descripción </p>
          </div>
          <div class="block">
            <textarea v-if="modeEdit" class="textarea" v-model="localDescription" placeholder="Descripción" />
            <p v-else class="text_16 text_green_dark"> {{ description }} </p>
          </div>
        </div>
        <div class="column is-5">
          <div class="block">
            <p class="paragraph text_green_dark has-text-weight-bold"> Fecha de creación </p>
          </div>
          <div class="block">
            <p class="text_16 text_green_dark"> {{ formatDate(creationDate, 'DD/MMM/YYYY') }} </p>
          </div>
        </div>
        <div class="column is-5">
          <div class="block is-flex is-align-items-center">
            <p class="paragraph text_green_dark text_primary_black has-text-weight-bold mr-6">  Estatus </p>
            <span v-if="modeEdit">
              <input id="switchStatus" type="checkbox" name="switchStatusStatus" class="switch is-rounded is-small" v-model="localStatus" :checked="localStatus ? 'checked' : ''">
              <label for="switchStatus"></label>
            </span>
            <p v-else class="text_16" :class="[ status ? 'text_green' : 'text_error' ]"> {{ status ? 'Habilitado' : 'Deshabilitado' }} </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '@/mixins/formats'

export default {
  name: 'CardGeneralInformationGuide',
  mixins: [mixins],
  inject: ['$validator'],
  data () {
    return {
      localDescription: this.description,
      localStatus: this.status
    }
  },
  props: {
    description: {
      type: String,
      default: null
    },
    creationDate: {
      type: String,
      default: null
    },
    status: {
      type: Boolean,
      default: false
    },
    modeEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getDataGeneralInformation () {
      const dataGeneral = {
        localDescription: this.localDescription,
        localStatus: this.localStatus
      }
      return dataGeneral
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
  }
</style>
